// Anything exported from this file is importable by other in-browser modules.
import "./global.css";
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import PageHeader from "./components/generic/page-header.vue";
import NoData from "./components/dataDisplay/noData.vue";
import LoadingData from "./components/dataDisplay/LoadingData.vue";
import DataTableWithSlideout from "./components/generic/dataTable/DataTableWithSlideout.vue";

new Vue({});

Vue.use(vuetify);

// You can export Vue components from this file and import them into your microfrontends
// export { default as PageHeader } from "./components/generic/page-header.vue";
// export { default as NoData } from "./components/dataDisplay/noData.vue";

export { PageHeader, NoData, LoadingData, DataTableWithSlideout };
