




































































import Vue from 'vue';

export default {
	props: ["title"],
		data: () => ({
			menu: false
		})
}
