























  /**
   * The only true button.
   * @displayName Best Button
   */
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class LoadingData extends Vue {
	/**
       * The text the is shown when the component is loading.
       */
	@Prop() loadingDataText!: string;
	
	/**
       * The heading - mainly used for project
       * @values Customer Support, Customers, Accounts
       */
	@Prop() project!: string;
}
