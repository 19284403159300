




















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

// import LoadingData from "src/components/dataDisplay/LoadingData.vue";
// import NoData from "src/components/dataDisplay/noData.vue";

// Components
/**
 * @displayName Data Table With Slideout
 * Example of Usage
 * <pre><code><data-table-with-slideout 
 * 	:headers="yourHeadersArrayHere" 
 * 	:items="yourItemsArrayHere" 
 * 	:isLoading="isLoading" @row-click="yourListeningMethodHere">
 * </data-table-with-slideout></code></pre>
 * 
 * Example of importing in project
 * <pre><code>import { NoData, DataTableWithSlideout } from '@gz/styleguide';</code></pre>
 */
@Component({
  components: {
    // NoData,
    // LoadingData
  }
  // mounted: function() {},
})
export default class DataTableWithSlideout extends Vue {

/**
   * Property to setup table headers
	 * [Table Documentation](https://vuetifyjs.com/en/api/v-data-table/#props-header)
 */
  @Prop() headers!: any[];

	/**
		 * Key for the datatable to do selecting etc... e.g. Tickets -> TicketId
	*/
  @Prop() itemKey!: string;

	/**
		 * Items the table will display, can be any array you want as long as you setup the headers to match
	 */
  @Prop() items!: object[];
  @Watch("items")
  onValueChanged(val: any, oldVal: any) {
    if (val.length === 0) {
      this.showGridCheckboxes = false;
    }
  }
/**
   * Property to show the loading bar on the table
 */
  @Prop() isLoading!: boolean;

  selectedItems: any[] = [];
  page = 1;
  itemsPerPage = 20;
  pageCount = 0;
  showGridCheckboxes = false;
  rowSlideOut = false;
	isSingleSelect = true;
  excludeHeaders: any = [];
  itemsBackup: any = [];

  pagination: any = {
    rowsPerPage: 25
  };

  RowInputEvent(items: any[]) {
    if (items.length > 1 || items.length == 0) {
      this.rowSlideOut = false;
    } else {
      this.rowSlideOut = true;
    }
  }

  EmitRowClick(item: any, value: any) {
    /**
     * Triggers when a row is left clicked
	   * [Event Documentation](https://vuetifyjs.com/en/api/v-data-table/#events-click:row)
     * @param {object} item the selected item from your grid
     * @param {object} value the value of the grid item
		*/
    this.$emit("row-click", item, value);

		if (this.selectedItems.length > 0 && !this.showGridCheckboxes && !this.isSingleSelect) {
      this.showGridCheckboxes = true;
      this.rowSlideOut = false;
    }


    value.select(!value.isSelected);

    if (this.selectedItems.length === 0 && this.showGridCheckboxes) {
      this.showGridCheckboxes = false;
			this.isSingleSelect = true;
    }
  }

	created(){
		 window.addEventListener('keydown', (e) => {
      if (e.key == 'Shift' || e.key == 'Control') {
				if (!this.showGridCheckboxes) {
					this.isSingleSelect = false;
				}
      }
    });

		window.addEventListener('keyup', (e) => {
      if (e.key == 'Shift' || e.key == 'Control') {
				if (!this.showGridCheckboxes) {
					this.isSingleSelect = true;
				}
      }
    });
	}


	destroyed(){
		window.removeEventListener('keydown', () => {});
		window.removeEventListener('keyup', () => {});
	}

  DeleteProps(item: any) {
    const excludeHeaders = Object.keys(item).filter(
      (xx: any) => !this.headers.find((yy: any) => yy.value == xx)
    );

    for (let index = 0; index < excludeHeaders.length; index++) {
      delete item[excludeHeaders[index]];
      const element = excludeHeaders[index];
    }
    return item;
  }

  pages() {
    if (
      this.pagination.rowsPerPage == null ||
      this.pagination.totalItems == null
    )
      return 0;

    return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
  }
}
