























import { Component, Prop, Vue } from 'vue-property-decorator';
// import { Address } from '../classes/Address'; // example import

@Component
export default class NoData extends Vue {
  @Prop({ default: 'Label' })
  label!: string;

  @Prop({ default: 'Icon' })
  icon!: string;
}
