import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#4fa928",
        secondary: "#8C16BD",
        tertiary: colors.orange.base,
        accent: "#8c9eff",
        error: "#D41116",
        warning: "#F59D1D",
        grey: "",
      },
    },
  },
});
